import React from 'react';
import { Comments } from 'react-facebook';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import getLanguage from '../utils/getLanguage';

export default function About() {
  const language = getLanguage();

  return (
    <Layout page="guestbook">
      <SEO title="Guestbook" />
      <div className="textContent">
        <h2>Guestbook</h2>
        {language === 'en' ? (
          <p>Please feel free to leave messages for the bride and groom here.</p>
        ) : (
          <p>ฝากข้อความถึงเจ้าบ่าวเจ้าสาวกันได้เลยนะครับ/ค่ะ</p>
        )}
      </div>
      <p>&nbsp;</p>
      <div className="textContent">
        <Comments href="http://benandmuu.yellowpigz.com/guestbook" numPosts={10} width="636" />
      </div>
    </Layout>
  );
}
